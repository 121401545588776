import {
    RecoilRoot,
    atom,
    selector,
    useRecoilState,
    useRecoilValue,
    useSetRecoilState,
  } from 'recoil';

import { get, set } from 'idb-keyval';
import React, { useState, useEffect } from 'react';
import {CardPortal, DGVAPIToken, HoverInfo, isDataLoading} from '../GlobalState'
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import { cardActionAreaClasses, Stack } from '@mui/material';
import Portal from '@mui/base/Portal';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import {DeckLayers} from '../GlobalState'
import {ScatterplotLayer, LineLayer, IconLayer} from '@deck.gl/layers';
import { DGVClickInfo, DGVData, DGVTargetLayerVisibility, dgvEventStats, DGVEventDataDetails, DGVEventPlayback} from './DGVState';
import {DGVCard} from './DGVCard'
import vessel from 'data-url:../Maritime/vessel.svg'
import { DGVTargetLastEventType, DGVEventData, DGVTargetLayerVisibility } from './DGVState';
import {DataFilterExtension} from '@deck.gl/extensions';

const ICON_MAPPING = {
  vessel: {x:0, y:0, width:12, height:24, mask:true}
}

let dgvAbortController = new AbortController();

let baseUrl = `dgv/targets?position_bbox=-92,-182,92,182&`

let getDGVTargetData = async ({dgvAPIToken, setDGVData, dgvAbortController, nextUrl=baseUrl}) => {
    try{
        let response = await fetch(nextUrl,{
            signal: dgvAbortController.signal,
            headers:{'x-api-key': dgvAPIToken}
        });

        let data = await response.json()

        setDGVData(prev => {
            let {features} = data
            let priorData = structuredClone(prev);
            for (feature of features) {
                let {properties} = feature
                priorData[properties.mmsi] = properties;
            }
            return priorData;
        });
        
        
        if(data.links?.length > 0 ){
            for (link of data.links) {
                if (link.nextPageUrl) {
                    let nextUrl = link.nextPageUrl.split('/').slice(3).join('/')
                    getDGVTargetData({dgvAPIToken, setDGVData, dgvAbortController, nextUrl})
                }
            }
        }

        
    } catch (e) {
        console.log(`Error fetching DGV Data: ${e}`)
    }
}

const colorDGVTargets = {
  "PV NON EVENT":[0,0,0],
  "PV 181/91": [255,255,0],
  "PV OUT OF FOOTPRINT":[255,0,255],
  "PV POOR FIT": [128,0,128],
  "PV FIXED": [0,128,128],
}

let getDateString =(dtg)=>{
  return parseInt(new Date(dtg).getTime())
}

let DGVDataComponent= ()=>{
    const [displayDGVOptions, setDisplayDGVOptions] = useState(false)
    const [dataSwitch, setDataSwitch] = useState(false)
    const [layerVisibility, setLayerVisibilty] = useRecoilState(DGVTargetLayerVisibility)
    const [cardPortal, setCardPortal] = useRecoilState(CardPortal);
    const [dgvData, setDGVData] = useRecoilState(DGVData)
    const [dgvAPIToken] = useRecoilState(DGVAPIToken)
    const [layers, setLayers] = useRecoilState(DeckLayers);
    const [dgvClickInfo, setDGVClickInfo] = useRecoilState(DGVClickInfo)
    const [dgvTargetLastEventType] = useRecoilState(DGVTargetLastEventType);
    const [dataAction, setDataAction] = useState('append');
    const [hoverInfo, setHoverInfo] = useRecoilState(HoverInfo);
    const [selectionModel, setSelectionModel] = React.useState(['PV FIXED', 'PV OUT OF FOOTPRINT', 'PV 181/91']);
    const [selectionModelVesselType, setSelectionModelVesselType] = React.useState(['FISHING', 'TUG', 'DRY_BULK', '', 'CONTAINER', 'OTHER', 'PASSENGER', 'GENERAL_CARGO', 'TANKER_PRODUCT', 'CAR_CARRIER', 'PLEASURE_CRAFT', 'GAS_CARRIER', 'OFFSHORE', 'TANKER_CRUDE', 'PILOT_VESSEL', 'SEARCH_AND_RESCUE', 'VEHICLE_PASSENGER', 'ROLL_ON_ROLL_OFF', 'TANKER_CHEMICALS', 'SAILING', 'HIGH_SPEED_CRAFT', 'ANTI_POLLUTION', 'REEFER', 'MILITARY_OPS', 'DREDGER', 'LAW_ENFORCEMENT', 'LNG_CARRIER', 'PORT_TENDER', 'GENERAL_TANKER', 'DIVE_VESSEL', 'null', 'SPECIAL_CRAFT', 'LIVESTOCK']);
    const setIsLoading = useSetRecoilState(isDataLoading)
    const [dgvEventData, setDGVEventData] = useRecoilState(DGVEventData)
    const [dgvEventPlayback, setdgvEventPlayback] = useRecoilState(DGVEventPlayback)
    const [animation, setAnimation] = useState(null)
    const [time, setTime] = useState(0);

    let nextUrl = baseUrl
    
    if(dgvTargetLastEventType.length > 0){
      nextUrl = nextUrl+'&rfgl_anomalies_to_include='+dgvTargetLastEventType.join(',')
    }

    let handleDataAction = (event, newDataAction)=>{
      setDataAction(newDataAction)
    }

    let handleDisplayDGVOptions = () => {
        setDisplayDGVOptions((prev)=> !prev)
    }

    let handleLayerVisibility = () => {
        setLayerVisibilty(prev => !prev)
    }

    let handleDGVData = ()=> {
        setDataSwitch(prev => {
            if(prev){
                dgvAbortController.abort();
                dgvAbortController = new AbortController();
                return !prev;
            } else {
                setDGVData({})
                setIsLoading(true)
                getDGVTargetData({dgvAPIToken, setDGVData, dgvAbortController, nextUrl}).then(()=> setIsLoading(false)) 
                return !prev;
            }
        })
    }

    let handleHoverLine = (info) =>{
      if (info.object){
        const layer = new LineLayer({
          id: 'dgv-event-layer-line',
          data: [info.object],
          pickable: false,
          getWidth: 1,
          getColor: d => colorDGVTargets[d.rfgl_event_type]||[0,0,255],
          getSourcePosition: d => [d.rfgl_reported_longitude, d.rfgl_reported_latitude],
          getTargetPosition: d => [d.rfgl_estimated_longitude, d.rfgl_estimated_latitude],
        });

        setLayers((prev)=> [...prev.filter(d => d.id != 'dgv-event-layer-line'), layer]);

      } else {
        setLayers((prev)=> [...prev.filter(d => d.id != 'dgv-event-layer-line')]);
      }
    }


    
    useEffect(()=>{
        if(dgvData && layerVisibility){
            const newlayer = 
                new IconLayer({id: 'dgv-layer', 
                            data: Object.values(dgvData).filter(d=> selectionModel.includes(d.rfgl_latest_anomaly_event|| 'PV NON EVENT')).filter(d => selectionModelVesselType.includes(d.vessel_type_main)),    
                            sizeScale:8,
                            pickable: true,
                            iconAtlas: vessel,
                            iconMapping: ICON_MAPPING,
                            sizeMaxPixels: 15,
                            sizeMinPixels: 10, 
                            getIcon: d => 'vessel',
                            getAngle: value =>  360 - value.heading || 0,
                            getColor: d => colorDGVTargets[d.rfgl_latest_anomaly_event]||[0,0,0],
                            getPosition: (value) =>  [value.longitude, value.latitude], 
                            onHover: (info,event) => {
                              setHoverInfo(info)
                            },
                            onClick: (info, event)=>{
                              let record = structuredClone(info.object)
                              setDGVClickInfo((prev)=> [...prev.filter(d => d.mmsi != record.mmsi), record]);
                            }
                          })
              ;
            
            setLayers((prev)=> [...prev.filter(d => d.id != 'dgv-layer'), newlayer]);          

        } else if (!layerVisibility) {
            setLayers((prev) => {
                prev = prev.filter(d => d.id != 'dgv-layer')
                return prev
              });
        }
    }, [dgvData,layerVisibility, selectionModel, selectionModelVesselType])


    const animate = () => {
      setTime(t => {
        movement = t + 1
        return movement
      });
      
    };


    React.useEffect(()=>{
      if(dgvEventPlayback){
        setAnimation(window.setInterval(animate, 10));
      } else {
        window.clearInterval(animation)
      }

      if(dgvEventData.length > 0){

        let timeIndex = dgvEventData.map(d=> getDateString(d.rfgl_dtg)).sort((a,b)=> a-b)

        if (time > timeIndex.length ) {setTime(0); setdgvEventPlayback(false);}

        const dgv_reported = 
                new IconLayer({id: 'dgv-event-layer-reported', 
                            data: dgvEventData,    
                            sizeScale:15,
                            pickable: true,
                            iconAtlas: vessel,
                            iconMapping: ICON_MAPPING,
                            sizeMaxPixels: 6,
                            sizeMinPixels: 5, 
                            getIcon: d=> 'vessel',
                            getAngle: value =>  value.cog || 0,
                            getColor: [0,0,0],
                            getPosition: (value) =>  [value.rfgl_reported_longitude, value.rfgl_reported_latitude], 
                            onHover: (info,event) => {
                              handleHoverLine(info)
                              setHoverInfo(info)
                            },
                            onClick: (info, event)=>{
                              let record = structuredClone(info.object)
                              setDGVClickInfo((prev)=> [...prev.filter(d => d.mmsi != record.mmsi), record]);
                            },
                            extensions: [new DataFilterExtension({filtersize:1})],
                            filterEnabled: dgvEventPlayback,
                            getFilterValue: f => getDateString(f.rfgl_dtg),
                            filterRange:[timeIndex[0],timeIndex[time+1]],
                            filterSoftRange:[timeIndex[time],timeIndex[time+1]]
                          })            

              const dgv_estimated = 
              new IconLayer({id: 'dgv-event-layer-estimated', 
                          data: dgvEventData,    
                          sizeScale:15,
                          pickable: true,
                          iconAtlas: vessel,
                          iconMapping: ICON_MAPPING,
                          sizeMaxPixels: 6,
                          sizeMinPixels: 5, 
                          getIcon: d => 'vessel',
                          getAngle: value =>  value.cog || 0,
                          getColor: d => colorDGVTargets[d.rfgl_event_type]||[0,0,255],
                          getPosition: (value) =>  [value.rfgl_smoothed_longitude, value.rfgl_smoothed_latitude], 
                          onHover: (info,event) => {
                            handleHoverLine(info)
                            setHoverInfo(info)
                          },
                          onClick: (info, event)=>{
                            let record = structuredClone(info.object)
                            setDGVClickInfo((prev)=> [...prev.filter(d => d.mmsi != record.mmsi), record]);
                          },
                          extensions: [new DataFilterExtension({filtersize:1})],
                          filterEnabled: dgvEventPlayback,
                          getFilterValue: f => getDateString(f.rfgl_dtg),
                          filterRange:[timeIndex[0],timeIndex[time+1]],
                          filterSoftRange:[timeIndex[time],timeIndex[time+1]]
                        
                        })
            ;
            setLayers((prev)=> [...prev.filter(d => d.id != 'dgv-event-layer-reported').filter(d => d.id != 'dgv-event-layer-estimated'), dgv_estimated, dgv_reported]); 

      } else {
            setTime(0)
            setLayers((prev)=> [...prev.filter(d => d.id != 'dgv-event-layer-reported').filter(d => d.id != 'dgv-event-layer-estimated')])
      }

      return () => window.clearInterval(animation)

    }, [dgvEventData,dgvClickInfo, dgvEventPlayback, time])


   return (
     <React.Fragment>
        <Button variant="text" color="secondary" onClick={handleDisplayDGVOptions}>Doppler Geolocation Verification (DGV)</Button>
        {cardPortal && displayDGVOptions ? <Portal container={cardPortal.current}>
        <Card sx={{minWidth:400}}><CardContent>
         <FormGroup sx={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
          <FormControlLabel control={
            <Switch
              checked={dataSwitch}
              onChange={handleDGVData}
              color='info'
              
            />
          } label="DGV Data" />
          <FormControlLabel control={
            <Switch
              checked={layerVisibility}
              onChange={handleLayerVisibility}
              color='info'
              
            />
          } label="DGV Target Layer" />
          </FormGroup>
          <DGVTable dgvStats={dgvData}  
          selectionModel={selectionModel} setSelectionModel={setSelectionModel} 
          selectionModelVesselType={selectionModelVesselType} setSelectionModelVesselType={setSelectionModelVesselType}
          />
          </CardContent>
          </Card>
          </Portal> : '' }
          {cardPortal && dgvClickInfo.length > 0 ? <Portal container={cardPortal.current}>
            <DGVCard/>
          </Portal> : ''}
          </React.Fragment>)
  } 


  import * as React from 'react';
  import { DataGrid } from '@mui/x-data-grid';
  import { ConstructionOutlined } from '@mui/icons-material';
  
  const eventColumns = [
    { field: 'event_type', headerName: 'Event Type', width: 150 },
    { field: 'count', headerName: 'Target Count', width: 150 },
  ];

  const vesselTypeColumns = [
    { field: 'vessel_type', headerName: 'Vessel Type', width: 150 },
    { field: 'count', headerName: 'Target Count', width: 150 },
  ];
  
  
  let DGVTable = ({dgvStats, selectionModel, setSelectionModel, selectionModelVesselType, setSelectionModelVesselType})=> {
    let vesselStats = dgvEventStats(dgvStats)
    let eventRows = Object.entries(vesselStats.rfgl_latest_anomaly_event).map(d =>{ return {"id":d[0], "event_type":d[0], "count":d[1]}})
    let vesselTypeRows = Object.entries(vesselStats.vessel_type_main).map(d =>{ return {"id":d[0], "vessel_type":d[0], "count":d[1]}})

    

    return (
      <React.Fragment>
        <DataGrid sx={{minHeight:300}}
          rows={eventRows}
          columns={eventColumns}
          checkboxSelection
          onSelectionModelChange={(newModel =>{
            setSelectionModel(newModel)
          })}
          selectionModel={selectionModel}
          hideFooter
          components={{
            Pagination: '',
          }}
          initialState={{
            sorting:{
              sortModel:[{field:'count', sort:'desc'}]
            }
          }}
        />
        <DataGrid sx={{minHeight:375}}
          rows={vesselTypeRows}
          columns={vesselTypeColumns}
          checkboxSelection
          onSelectionModelChange={(newModel =>{
            setSelectionModelVesselType(newModel)
          })}
          selectionModel={selectionModelVesselType}
          
          components={{
            Pagination: '',
          }}
          initialState={{
            sorting:{
              sortModel:[{field:'count', sort:'desc'}]
            }
          }}
        />
      </React.Fragment>
    );
  }



  export {DGVDataComponent, colorDGVTargets}